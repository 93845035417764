import { apiClient } from "../../../../service";

export const viewApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      return `${key}=${filter[key]}`;
    })
    .join("&");
  return apiClient.get(`/user/view?${queryString}`);
};
export const addApi = (form) => apiClient.post(`/user/create`, form);
export const deleteApi = (id) => apiClient.post(`/user/delete/${id}`);
export const detailApi = (id) => apiClient.get(`/user/detail/${id}`);
export const targetApi = (id) => apiClient.get(`/user/suksesor-target/${id}`);
export const progresApi = (id) => apiClient.get(`/user/suksesor-progres/${id}`);
export const editApi = (id, form) => apiClient.post(`/user/update/profile/${id}`, form);
export const gantiPassApi = (id, form) =>
  apiClient.post(`/user/update/password/${id}`, form);
