import { createStore, createHook } from "react-sweet-state";
import { apiClient } from "../../../service";
import { checkUserApi, loginApi, logoutApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    profile: null,
    isAuthed: false,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState }) => {
        setState(newState);
      },
    login:
      ({ username, password }) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await loginApi({ username, password });
          if (res.data && res.status === 200) {
            window.localStorage.setItem("token", res.data.token);
            apiClient.defaults.headers = {
              Authorization: `Bearer ${res.data.token}`,
              'x-api-keyyy': process.env.REACT_APP_APIKEY
            };
            setState({
              isLoading: false,
              isAuthed: true,
              profile: res.data.profile,
            });
            return res.data;
          }
        } catch (err) {
          setState({
            isLoading: false,
          });
          return null;
        }
      },
    checkUser:
      () =>
      async ({ setState }) => {
        try {
          const res = await checkUserApi();
          if (res.data && res.data.code === 200) {
            setState({
              isLoading: false,
              isAuthed: true,
              profile: res.data.payload,
            });
            return res.data.payload;
          }
        } catch (err) {
          setState({
            isLoading: false,
          });
          return null;
        }
      },
    logout:
      () =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          // const res = await logoutApi();
          // if (res.data && res.status === 200) {
            
            setState({
              isLoading: false,
              profile: null,
              isAuthed: false,
            });
            localStorage.clear();
            return true;
          // }
        } catch (err) {
          setState({
            isLoading: false,
          });
          return null;
        }
      },
  },
});

export const useLoginStore = createHook(Store);
