import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function InputSelect({
  label,
  onChange,
  name,
  value,
  readOnly,
  required,
  options,
  disabled,
}) {
  return (
    <div className="div-input">
      <div className="label-input">{label}</div>
      <Select
        value={value}
        name={name}
        readOnly={readOnly}
        onChange={onChange}
        required={required}
        fullWidth
        disabled={disabled}
        size="small"
      >
        {options &&
          options.map((e, i) => (
            <MenuItem key={i} value={e}>
              {e}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
